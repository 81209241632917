.typewriter h3{
  margin: 0 auto;
}

.typewriter h3 {
  animation: typing 3.5s steps(40, end),
  blinking-cursor .5s step-end infinite;
}

.cursor::after {
    content: '';
    display: inline-block;
    margin-left: 3px;
    background-color: white;
    animation-name: blink;
    animation-duration: .5s;
    animation-iteration-count: infinite;
}

@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blinking-cursor {
  from, to { border-color: transparent }
  50% { border-color: orange }
}

/* end of animations */
